import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { NavController, Platform, ModalController } from '@ionic/angular';
import { AreaService } from 'src/app/services/Area/area.service';
import { VendorAddressService } from 'src/app/services/vendor-address/vendor-address.service';
import { ToastComponent } from 'src/app/shared/toast/toast.component';

declare var google:any;

@Component({
  selector: 'app-set-location',
  templateUrl: './set-location.component.html',
  styleUrls: ['./set-location.component.scss'],
})
export class SetLocationComponent implements OnInit {

	latitude: number;
	longitude: number;
	zoom: number;
	address: string;
	private geoCoder;
	areaObj:any;
	@ViewChild('search')
	public searchElementRef: ElementRef;
	autocompleteItems:any = [];
	value:any;
	userdata:any;
	editflag:boolean = false;
	useraddress:any;
  showMapsFlag:boolean = false;
  allAddress:any;
  place: any;
  selectedAddress: any;
  constructor(
	private modal:ModalController,
    private vendorAddressService: VendorAddressService,
	private toast:ToastComponent,
	private areaService:AreaService,
	
	) { 
		this.userdata = JSON.parse(localStorage.getItem('userProfile'));
		this.vendorAddressService
		.getAllVendorAddresss(this.userdata.id)
		.then((res: any) => {
		  console.log(res);
		  if (res.length > 0) {
			if (JSON.stringify(this.useraddress) !== JSON.stringify(res[0])) {
			  localStorage.setItem("location", JSON.stringify(res[0]));
			  this.useraddress = res[0];
			}
			this.editflag = true;
		  }
		  this.areaService.getEveryAreas().then((res)=>{
			console.log(res);
			this.allAddress = res;
			console.log(this.address);
			this.allAddress.map((element)=>{
				element.selected = false
			})	
			if(this.useraddress.addressIds){
				this.useraddress.addressIds.map((elem)=>{
					this.allAddress.map((element)=>{
						if(element.id == elem){
							element.selected = true;
						}
					})
				})
			}else{
				this.allAddress.map((element)=>{
					element.selected = false
				})	
			}
			
		  })
		});
	
  }
  


	goback(){
		this.modal.dismiss();
	  }
	
	  ngOnInit() {
	  }
  
	  saveLocation(){
		console.log("address",this.allAddress);
		let addressIds=[];
		this.allAddress.map((ele)=>{
			if(ele.selected){
				addressIds.push(ele.id);
			}
		})		
		setTimeout(() => {
			console.log(addressIds)
			if(this.useraddress && this.useraddress.id){
				this.vendorAddressService.editVendorAddress({"addressIds":addressIds},this.useraddress.id).then((res)=>{
					console.log(res);
					localStorage.setItem("location", JSON.stringify(res));
					this.modal.dismiss();
				})
			}else{
				let data ={
					"addressIds":addressIds,
					"userId": this.userdata.id,
				}
				this.vendorAddressService.addVendorAddress(data).then((res)=>{
					console.log(res);
					localStorage.setItem("location", JSON.stringify(res));
					this.modal.dismiss();
				})
			}
		}, 200);
    }
  

}
